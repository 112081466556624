<template>
  <div class="text-gray2">
    splash
  </div>
</template>
<script>
// import router from '../../router/index'
export default {
  data () {
    return {}
  },
  mounted () {
  //  if (localStorage.getItem('jobUserInfo') === null) {
  //    this.$router.push({name: 'login'})
  //  } else {
  //   this.$router.push({name: 'dashboard'})
  //  }
    // router.beforeEach(async () => {
    //   if (localStorage.getItem('jobUserInfo') === null) {
    //     this.$router.push({name: 'login'})
    //   } else {
    //     this.$router.push({name: 'dashboard'})
    //   }
    // })
  },
  methods: {}
}
</script>

<style scoped>
</style>
